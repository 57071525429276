function myMap() {
    var mapProp = {
        center:new google.maps.LatLng(50.0692589,19.9402805,19.25),
        zoom:15,
    };
    var map = new google.maps.Map(document.getElementById("main-map"),mapProp);


      var icons = {
      info: {
        icon: 'pointer.png'
      }
    };

    var features = [
         {
        position: new google.maps.LatLng(50.0677719,19.941307,18),
        type: 'info'
      }
    ];

     features.forEach(function(feature) {
      var marker = new google.maps.Marker({
        position: feature.position,
        icon: icons[feature.type].icon,
        map: map
      });
    });
}