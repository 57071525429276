$(function(){

    // menu 
    $('.container-hamburger').click(function(){
      $('.main-nav').slideToggle();
      $('.reservation-button').toggleClass('reservation-button-mobile');
    });

    $(document).on("scroll",function() {
      if($(document).scrollTop()>50)
      {
        $('.header-menu').addClass('header-menu-active'); 
        $('.reservation-button').addClass('reservation-button-active');        
      } 
      else
      {
        $('.header-menu').removeClass('header-menu-active');
        $('.reservation-button').removeClass('reservation-button-active');       
      }
    });

    //single offert

    $('.offert-box__button-more span').click(function(){
      $(this).parent().next().slideToggle();
    });

    // mixitup 
    var mixer = mixitup(".offert-container");

    // galleria lista
    $('.gallery-list li').click(function(){
      $(this).addClass('is-active');
      $(this).siblings().removeClass('is-active');
    });

});

