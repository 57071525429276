var swiperHome = new Swiper('.swiper-main', {
  pagination: {
    el: '.swiper-pagination-main',
  },
  autoplay: {
    delay: 2000,
  },
});

var swiperAboutUs = new Swiper('.swiper-about-us', {
  navigation: {
    nextEl: '.swiper-button-next-about-us',
    prevEl: '.swiper-button-prev-about-us',
  },
});

var swiper = new Swiper('.swiper-offert', {
  slidesPerView: 4,
  spaceBetween: 30,
  pagination: {
    el: '.swiper-pagination-offert',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next-offert',
    prevEl: '.swiper-button-prev-offert',
  },
  breakpoints: {
    1250: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    900: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    600: {
      slidesPerView: 1,
    },
  }
});

var swiper = new Swiper('.swiper-workers', {
  slidesPerView: 3,
  spaceBetween: 30,
  pagination: {
    el: '.swiper-pagination-workers',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next-workers',
    prevEl: '.swiper-button-prev-workers',
  },
  breakpoints: {
    1250: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    900: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    600: {
      slidesPerView: 1,
    },
  }
});